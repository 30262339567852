
export default {
  props: {
    isFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      buttonText: '구독하기 Subscribe',
      buttonClass: 'subscribe-button',
      isSubmitted: false,
    };
  },
  methods: {
    async submitNewsletter() {
      try {
        const userData = {
          email: this.email,
        };
        this.isSubmitted = true;
        await this.upload(userData);
        this.clearInput();
        this.changeButton();
      } catch (err) {
        await this.$dialog.alert({
          message: err.response.data.error,
          confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
          confirmButtonColor: '#f32d2d',
        });
        this.isSubmitted = false;
      }
    },
    clearInput() {
      this.email = '';
    },
    async upload(userData) {
      const data = await this.$axios.post('/newsletter', userData);
      return data;
    },
    changeButton() {
      this.buttonClass = 'subscribed-button';
      this.buttonText = '구독했습니다! Subscribed!';
    },
  },
};
