
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: null,
    },
    username: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
};
