
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
export default {
  components: { VueSlickCarousel },
  data() {
    return {
      settings: {
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        touchThreshold: 5,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 5000,
        pauseOnHover: true,
        centerMode: true,
        centerPadding: '50px',
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
              adaptiveHeight: true,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
            }
          },
          {
            breakpoint: 1800,
            settings: {
              slidesToShow: 3,
            }
          }
        ]
      },
    }
  }
};
