
export default {
  props: {
    selectedNavigation: {
      type: String,
      default: null,
      validator: (name) => ['my-classroom', 'courses', 'us', 'resources', 'store'].includes(name),
    },
    me: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visibleMenu: false,
      visibleDropdown: false,
      unreadMessagesCount: 0,
    };
  },
  methods: {
    async handleLogout() {
      this.$store.dispatch('auth/userLogout');
      this.$router.push({ name: 'index' });
    },
    getIsSelected(value) {
      return this.selectedNavigation === value;
    },
    openMenu() {
      this.visibleMenu = true;
      setTimeout(() => {
        const navigationClassList = document.getElementById('navigation').classList;
        navigationClassList.add('responsive');
      }, 200);
    },
    closeMenu(data) {
      const { visibleMenu, visibleDropdown } = data;
      if (visibleMenu) {
        this.visibleMenu = false;
        const navigationClassList = document.getElementById('navigation').classList;
        navigationClassList.remove('responsive');
      }
      if (visibleDropdown) {
        this.visibleDropdown = false;
      }
    },
  },
};
