
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import layout from 'simple-keyboard-layouts/build/layouts/korean';

export default {
  props: {
    input: {
      type: String,
    },
  },
  data() {
    return {
      keyboard: null,
    };
  },
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      ...layout
    });
    this.keyboard.setInput(this.input);
  },
  methods: {
    onChange(input) {
      this.$emit("onChange", input);
    },
    onKeyPress(button) {
      this.$emit("onKeyPress", button);
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === "default" ? "shift" : "default";
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    onCloseKeyboard() {
      this.$emit("onCloseKeyboard");
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    }
  },
};
