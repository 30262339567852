import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=1f22f3ca&scoped=true&lang=pug"
import script from "./Dropdown.vue?vue&type=script&lang=js"
export * from "./Dropdown.vue?vue&type=script&lang=js"
import style0 from "./Dropdown.vue?vue&type=style&index=0&id=1f22f3ca&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f22f3ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNativeProfileButton: require('/home/ubuntu/sckool-web/components/main/native/ProfileButton.vue').default})
