
import Event from '@/mixins/Event';
import moment from 'moment';

export default {
  props: {
    me: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [Event],
  data() {
    return {
      formStatus: 'email',
      email: '',
      confirmationNumber: '',
      confirmationNumberUser: '',
      loginType: 'password',
      password: '',
      isSelected: false,
      isPasswordStrong: null,
    };
  },
  methods: {
    async handlePutEmail() {
      this.landingPageRegistrationStartEvent({ email: this.email });
      try {
        const user = await this.checkIfUserExists({
          email: this.email,
          loginType: this.loginType,
        });
        if (user.length > 0) {
          this.userAlreadyRegisteredEvent({ email: this.email, location: 'landing-page' });
          return await this.$dialog.alert({
            message: "이미 가입된 회원입니다. \nYou are already registered.",
            confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
            confirmButtonColor: '#f32d2d',
          });
        }
        if (this.me) {
          this.userAlreadyLoggedInEvent({ email: this.email });
          return await this.$dialog.alert({
            message: "이미 로그인되어 있습니다. \nYou are already logged in.",
            confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
            confirmButtonColor: '#f32d2d',
          });
        }

        const params = { email: this.email };
        const { confirmationNumber } = await this.sendConfirmationNumber({ params });
        this.emailConfirmationSentEvent({ email: this.email });
        this.confirmationNumber = confirmationNumber;
        this.formStatus = 'confirmation-number';
      } catch (error) {
        this.emailConfirmationNotSentEvent({ email: this.email });
        await this.$dialog.alert({
          message: error.response.data.message,
          confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
          confirmButtonColor: '#f32d2d',
        });
      }
    },
    async handleCheckConfirmationNumber() {
      if (this.confirmationNumber == this.confirmationNumberUser) {
        this.emailConfirmationSuccess({
          email: this.email,
          login: this.loginType,
        });
        this.formStatus = 'password';
      } else {
        this.emailConfirmationFailed({
          email: this.email,
          login: this.loginType,
        });
        await this.$dialog.alert({
          message: '잘못된  확인 번호입니다. \n Incorrect confirmation number.',
          confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
          confirmButtonColor: '#f32d2d',
        });
      }
    },
    async handleSignUp() {
      const loading = this.$toast.loading({ duration: 0 });
      try {
        const { token } = await this.postUser({
          email: this.email,
          newPassword: this.password,
          newPasswordConfirmation: this.password,
          loginType: this.loginType,
        });
        this.registrationCompleteEvent({
          email: this.email,
          login: this.loginType,
        });
        await this.userLogin(token);
      } catch (error) {
        await this.$dialog.alert({
          message: error.response.data.message,
          confirmButtonText: '✔️\u00a0\u00a0확인 Okay',
          confirmButtonColor: '#f32d2d',
        });
        this.registrationFailedEvent({
          email: this.email,
          login: this.loginType,
        });
      } finally {
        loading.clear();
      }
    },
    async sendConfirmationNumber(params) {
      const { data } = await this.$axios.get('/general/email-confirmation', params);
      return data;
    },
    async postUser(body) {
      const { data } = await this.$axios.post('/general/users', { ...body });
      return data;
    },
    async userLogin(token) {
      const maxAge = 3600 * 2 * 365;
      const expires = moment().add(maxAge, 'seconds').toDate();
      await this.$cookies.set('token', token, {
        expires,
        maxAge,
        path: '/',
      });
      await this.$store.dispatch('auth/userLogin');
      this.loginCompleteEvent({ login: this.loginType });
      this.$router.push({ name: 'index-my-classes' });
    },
    validatePassword(event) {
      this.password = event;
      if (!this.password) return (this.isPasswordStrong = null);
      const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/g;
      this.isPasswordStrong = regex.test(this.password);
    },
    async checkIfUserExists(params) {
      const { data } = await this.$axios.get('/general/users', { params });
      return data;
    },
  },
};
