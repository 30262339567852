
export default {
  props: {
    selectedNavigation: {
      type: String,
      default: null,
      validator: (name) => ['courses', 'us', 'store'].includes(name),
    },
  },
  data() {
    return {
      visibleMenu: false,
    };
  },
  methods: {
    getIsSelected(value) {
      return this.selectedNavigation === value;
    },
    openMenu() {
      this.visibleMenu = true;
      setTimeout(() => {
        const navigationClassList = document.getElementById('navigation').classList;
        navigationClassList.add('responsive');
      }, 200);
    },
    closeMenu() {
      this.visibleMenu = false;
      const navigationClassList = document.getElementById('navigation').classList;
      navigationClassList.remove('responsive');
    },
  },
};
