
export default {
  props: {
    prepend: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    value: { type: String, default: null },
    placeholder: { type: String, default: null },
    nativeType: { type: String, default: null },
    maxLength: { type: Number, default: null },
    showPassword: { type: Boolean, default: false },
    hasVirtualKeyboard: { type: Boolean, default: false },
    isKeyboardOpen: { type: Boolean, default: false },
    hasKeyboardImage: { type: Boolean, default: false },
    autosize: { type: [Boolean, Object], default: false }
  },
  methods: {
    onChange(input) {
      this.$emit('input', input);
    },
    onKeyPress(button) {
      if (button === "{enter}") {
        this.$emit('is-keyboard-open', false);
      }
    },
    onOpenKeyboard() {
      this.$emit("on-open-keyboard");
      this.$emit('is-keyboard-open', true);
    },
    onCloseKeyboard() {
      this.$emit('is-keyboard-open', false);
    },
    onInputFocus() {
      if (this.hasVirtualKeyboard) {
        this.$emit('on-open-keyboard');
      }
    }
  },
  computed: {
    computedIcon() {
      return this.hasVirtualKeyboard ? ['far', 'keyboard'] : ['far', 'lightbulb'];
    },
  },
};
