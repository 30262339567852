
import Event from '@/mixins/Event';
export default {
  mixins: [Event],
  props: {
    background: {
      type: String,
      default: 'blue',
    }
  },
  methods: {
    handleClick() {
      this.startNowButtonClickedEvent();
      this.$router.push(`/signup`);
    }
  }
}
