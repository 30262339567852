
import HeroSignupComponent from './HeroSignupComponent.vue';

export default {
  components: { HeroSignupComponent },
  props: {
    me: {
      type: Object,
      default: () => {},
    },
  },
};
