import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=925cfee6&scoped=true&lang=pug"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"
import style0 from "./Navigation.vue?vue&type=style&index=0&id=925cfee6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "925cfee6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNativeHeaderUserInformation: require('/home/ubuntu/sckool-web/components/main/native/header/UserInformation.vue').default,MainNativeHeaderDropdown: require('/home/ubuntu/sckool-web/components/main/native/header/Dropdown.vue').default})
