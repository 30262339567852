import { datadogRum } from '@datadog/browser-rum';

export default {
  methods: {
    loginCompleteEvent(data) {
      datadogRum.addAction('login-complete', {
        login: {
          type: data.login,
        },
      });

      this.$gtag.event('login', {
        method: 'Google',
      });
    },
    loginFailedEvent(data) {
      datadogRum.addAction('login-failed', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    emailConfirmationViewEvent(data) {
      datadogRum.addAction('email-confirmation-view', {
        user: {
          email: data.email,
        },
      });
    },
    emailConfirmationFailed(data) {
      datadogRum.addAction('email-confirmation-failed', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    emailConfirmationSuccess(data) {
      datadogRum.addAction('email-confirmation-complete', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    passwordChangeFailed(data) {
      datadogRum.addAction('password-change-failed', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    passwordChangeViewEvent(data) {
      datadogRum.addAction('password-change-view', {
        user: {
          email: data.email,
        },
      });
    },
    passwordResetSuccess(data) {
      datadogRum.addAction('password-reset-complete', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    passwordResetViewEvent(data) {
      datadogRum.addAction('password-reset-view', {
        user: {
          email: data.email,
        },
      });
    },
    passwordResetFailed(data) {
      datadogRum.addAction('password-reset-failed', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    passwordChangeSuccess(data) {
      datadogRum.addAction('password-change-complete', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    registrationViewEvent(data) {
      datadogRum.addAction('registration-view', {
        user: {
          email: data.email,
        },
      });
    },
    registrationCompleteEvent(data) {
      datadogRum.addAction('registration-complete', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });

      this.$gtag.event('sign_up', {
        method: 'Google',
      });
    },
    registrationFailedEvent(data) {
      datadogRum.addAction('registration-failed', {
        user: {
          email: data.email,
        },
        login: {
          type: data.login,
        },
      });
    },
    purchaseCompleteEvent(data) {
      datadogRum.addAction('purchase-complete', {
        course: {
          _id: data.course._id,
          name: data.course.name,
        },
        purchase: {
          _id: data.purchase._id,
          value: data.purchase.value,
        },
      });

      this.$gtag.event('purchase_complete', {
        course_id: data.course._id,
        user_id: this.me._id,
        course_name: data.course.name,
        price: data.purchase.value,
        purchase_id: data.purchase._id,
      });
    },
    purchaseFailedEvent(data) {
      datadogRum.addAction('purchase-failed', {
        course: {
          _id: data.course._id,
          name: data.course.name,
        },
        purchase: {
          _id: data.purchase._id,
          value: data.purchase.value,
        },
      });

      this.$gtag.event('purchase_failed', {
        course_id: data.course._id,
        user_id: this.me._id,
        course_name: data.course.name,
        price: data.purchase.value,
      });
    },
    landingPageGuestViewEvent() {
      datadogRum.addAction('landing-page-guest');
    },
    landingPageUserViewEvent() {
      datadogRum.addAction('landing-page-user');
    },
    contactPageTypeEvent(data) {
      datadogRum.addAction('contact-page-view', {
        type: data.type,
      });
    },
    landingPageRegistrationStartEvent(data) {
      datadogRum.addAction('landing-page-registration-start', {
        user: {
          email: data.email,
        },
      });
    },
    emailConfirmationNotSentEvent(data) {
      datadogRum.addAction('email-confirmation-not-sent', {
        user: {
          email: data.email,
        },
      });
    },
    emailConfirmationSentEvent(data) {
      datadogRum.addAction('email-confirmation-sent', {
        user: {
          email: data.email,
        },
      });
    },
    userAlreadyRegisteredEvent(data) {
      datadogRum.addAction('user-already-registered', {
        user: {
          email: data.email,
        },
        location: data.location,
      });
    },
    userAlreadyLoggedInEvent(data) {
      datadogRum.addAction('user-already-logged-in', {
        user: {
          email: data.email,
        },
      });
    },
    startNowButtonClickedEvent() {
      datadogRum.addAction('start-now-button-click');
    },
  },
  created() {
    if (this.me) {
      datadogRum.setUser({
        id: this.me._id,
        name: this.me.name,
        email: this.me.email,
      });
    }
  },
};
