
import { mapState } from 'vuex';
import HeroComponent from './.components/index/HeroComponent';
import SocialProofComponent from './.components/index/SocialProofComponent';
import StartNowButtonComponent from './.components/index/StartNowButtonComponent';
import Event from '@/mixins/Event';

export default {
  mixins: [Event],
  computed: {
    ...mapState({ me: (state) => state.auth.me }),
  },
  components: { HeroComponent, SocialProofComponent, StartNowButtonComponent },
  created() {
    if (!this.me) {
      this.landingPageGuestViewEvent();
    } else {
      this.landingPageUserViewEvent();
    }
  },
};
