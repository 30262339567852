import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=0a6677c8&scoped=true&lang=pug"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=0a6677c8&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a6677c8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNativeHeaderNavigation: require('/home/ubuntu/sckool-web/components/main/native/header/Navigation.vue').default,MainNativeHeaderGuestNavigation: require('/home/ubuntu/sckool-web/components/main/native/header/GuestNavigation.vue').default})
