
import ScrollMixin from '~/mixins/Scroll';

export default {
  computed: {
    scrolled() {
      return this.scrollY > 0;
    },
  },
  mixins: [ScrollMixin],
  props: {
    me: {
      type: Object,
      default: () => {},
    },
    selectedNavigation: {
      type: String,
      default: null,
      validator: (name) =>
        [null, 'my-classroom', 'courses', 'resources', 'store'].includes(name),
    },
  },
};
